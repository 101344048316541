import React from "react";
import ReactPaginate from 'react-paginate';

import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function Leads({ currentLeads, numberOfLeadsReceivedIn30Days }) {
    const leadItems = currentLeads.map(function(lead) {
        let propertyTypesList = lead.propertyTypes.map((propertyType, propertyTypeIndex) => {
            if(propertyTypeIndex === 0) {
                return (
                    <span key={propertyType.toString()}>
                        {propertyType}
                    </span>
                );
            } else {
                return (
                    <span key={propertyType.toString()}>
                        , {propertyType}
                    </span>
                );
            }
        });

        let areaList = lead.areas.map((area, areaIndex) => {
            if(areaIndex === 0) {
                return (
                    <span key={area.toString()}>
                        {area}
                    </span>
                );
            } else {
                return (
                    <span key={area.toString()}>
                        , {area}
                    </span>
                );
            }
        });

        let errorList = lead.errors.map((error, errorIndex) => {
            return <Alert key={error.toString()} variant='danger'>
                {error}
            </Alert>
        });

        const dateTimeDisplayOptions = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        };

        const currencyDisplayOptions = {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 0,
        };

        let leadStatusIconMap = {
            "Success": "✅",
            "In Progress": "📤",
            "Failed": "❌",
            "Error": "❌",
            "Warning": "⚠️",
            "Duplicate": "🔁",
        }

        let searchCount = lead["searchCount"];

        let completedString = "Completed at " + lead.timeEntered.toDate().toLocaleString("en-US", dateTimeDisplayOptions);
        if(searchCount) {
            completedString = searchCount + " matching properties found at " + lead.timeEntered.toDate().toLocaleString("en-US", dateTimeDisplayOptions);
        }

        if(lead.status !== "Success" && lead.status !== "In Progress") {
            completedString = "Failed at " + lead.timeEntered.toDate().toLocaleString("en-US", dateTimeDisplayOptions);
        }

        /*
            {(lead.status === "Failed" || lead.status === "Error") && <Button variant="primary">Retry</Button>}
        */

        return <Card key={lead.firstName + lead.lastName + lead.timeEntered.nanoseconds}>
            <Card.Header>{leadStatusIconMap[lead.status]} {lead.status}</Card.Header>
            <Card.Body>
                <Card.Title style={{textTransform: "capitalize"}}>{lead.firstName} {lead.lastName}</Card.Title>
                <Card.Text>
                    <b>Contact:</b> {lead.email} {lead.phone} <br/>
                    <b>Areas:</b> {areaList} <br/>
                    <b>Price Ranges:</b> {(parseInt(lead.priceRange.split("-")[0] + "000")).toLocaleString("en-US", currencyDisplayOptions)} - {(parseInt(lead.priceRange.split("-")[1] + "000")).toLocaleString("en-US", currencyDisplayOptions)} <br/> 
                    <b>Types:</b> {propertyTypesList} <br/>
                    <b>Baths:</b> {lead.bathrooms} <br/>
                    <b>Beds:</b> {lead.bedrooms} <br/>
                    {completedString} <br/>
                </Card.Text>
                {errorList}
            </Card.Body>
        </Card>
    });
    
    let leadStats = <>
        <Card>
            <Card.Header>🎉 Nice work</Card.Header>
            <Card.Body>
                <Card.Title>Your Lead Statistics</Card.Title>
                <Card.Text>
                    You have received <b>{numberOfLeadsReceivedIn30Days}</b> leads in the last 30 days.
                </Card.Text>
            </Card.Body>
        </Card>
    </>;
    
    if(numberOfLeadsReceivedIn30Days === 0) {
        leadStats = <></>
    }

    return <>
        <Stack gap={3}>
            {leadStats}
            {leadItems}
        </Stack>
    </>
}

function NoLeads(viewSetup) {
    return function() {
        return <>
            <Stack gap={3}>
                <Card>
                    <Card.Header as="h5">We haven't seen any leads...</Card.Header>
                    <Card.Body>
                        <Card.Title>Time to Double-Check Your Setup</Card.Title>
                        <Card.Text>
                                If you're a new user, it's time to complete all the setup steps outlined below.
                        </Card.Text>
                        <Card.Text>
                                Double-check your MLS system credentials and your lead generation email forwarding settings.
                        </Card.Text>
                        <Card.Text>
                                If you've recently created your account, please note that you may not see any leads until a new lead arrives.
                        </Card.Text>
                        <Card.Text>
                                Ensure that you have completed all the necessary setup steps as described in the instructions below.
                        </Card.Text>
                        <Button variant="primary" onClick={viewSetup}>View Setup Instructions</Button>
                    </Card.Body>
                </Card>
            </Stack>
        </>
    }
}

export function LeadSection(props) {

    const leadsPerPage = 10;
    console.log(`Loading items from ${0} to ${leadsPerPage}`);

    let sortedLeads = props.leads.sort((a, b) => b.timeEntered.seconds - a.timeEntered.seconds);

    let numberOfLeadsReceivedIn30Days = sortedLeads.filter((lead) => {
        let now = new Date();
        let thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(now.getDate() - 30);
        return lead.timeEntered.toDate() > thirtyDaysAgo;
    }).length;

    const [newOffset, setNewOffset] = React.useState(0);
    const [endOffset, setEndOffset] = React.useState(leadsPerPage);

    const currentLeads = sortedLeads.slice(newOffset, endOffset);
    let pageCount = Math.ceil(sortedLeads.length / leadsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const no = Math.ceil(event.selected * leadsPerPage);
        setNewOffset(no);
        setEndOffset(no + leadsPerPage);
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    };

    return (
        <>
            <Stack gap={3} className="fade-in">
                <Leads currentLeads={currentLeads} numberOfLeadsReceivedIn30Days={numberOfLeadsReceivedIn30Days} />
                <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={NoLeads(props.viewSetup)}
                />
            </Stack>
        </>
    );
}