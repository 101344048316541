import React, {useState} from "react";

import { Alert, Container, Row, Col, Button, Form, Stack, FloatingLabel} from 'react-bootstrap';
import { CanadianRealEstateBoards, LeadGenerationSystems } from "./Constants";
import { Country, State }  from 'country-state-city';
import { countryToAlpha2 } from "country-to-iso";
import Spinner from 'react-bootstrap/Spinner';

function ValidatePhoneNumber(input_str) {
    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
}

function WaitlistButton(props) {

    const [isClicked, setClicked] = useState(false);

    if(isClicked) {
        return (
            <Button disabled variant="primary">
                Thank you for joining the waiting list
            </Button>
        );
    } else {
        return (
            <Button onClick={()=>{
                props.addToWaitingList();
                setClicked(true);
            }} variant="primary">
                Add me to the waiting list
            </Button>
        );
    }
}

export class SignUp extends React.Component {
    constructor(props) {
        super(props);

        let subscribedLeadGenerationSystems = {};
        
        LeadGenerationSystems.forEach((leadGenerationSystem) => {
            subscribedLeadGenerationSystems[leadGenerationSystem.name] = false;
        });

        this.state = {
            // Step 0
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',

            // Step 1
            address: '',
            city: '',
            province: '',
            country: '',
            postalCode: '',

            // Step 2
            isRealtor: undefined,
            realtorAssociation: "",
            leadGenerationSystems : subscribedLeadGenerationSystems,

            // Step 3
            howDidYouHearAboutUs: '',

            // Misc
            createAccount: this.props.createAccount,
            addToWaitingList: this.props.addToWaitingList,
            errorMessage: undefined,
            loading: false,
            step: 0
        };

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeProvince = this.handleChangeProvince.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangePostalCode = this.handleChangePostalCode.bind(this);
        this.handleChangeIsRealtor = this.handleChangeIsRealtor.bind(this);
        this.handleChangeRealtorAssociation = this.handleChangeRealtorAssociation.bind(this);
        this.handleChangeLeadGenerationSystem = this.handleChangeLeadGenerationSystem.bind(this);
        this.handleChangeHowDidYouHeardAboutUs = this.handleChangeHowDidYouHeardAboutUs.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addToWaitingList = this.addToWaitingList.bind(this);

        this.onAccountCreationError = this.onAccountCreationError.bind(this);
        
        this.forwardStep = this.forwardStep.bind(this);
        this.backwardStep = this.backwardStep.bind(this);
    }

    handleChangeFirstName(event) {
        this.setState({firstname: event.target.value});
    }

    handleChangeLastName(event) {
        this.setState({lastname: event.target.value});
    }
  
    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleChangePhone(event) {
        this.setState({phone: event.target.value});
    }
  
    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    handleChangeConfirmPassword(event) {
        this.setState({confirmPassword: event.target.value});
    }

    handleChangeAddress(event) {
        this.setState({address: event.target.value});
    }

    handleChangeCity(event) {
        this.setState({city: event.target.value});
    }

    handleChangeProvince(event) {
        this.setState({province: event.target.value});
    }

    handleChangeCountry(event) {
        this.setState({country: event.target.value, province: ''});
    }

    handleChangePostalCode(event) {
        this.setState({postalCode: event.target.value});
    }

    handleChangeIsRealtor(event) {
        this.setState({isRealtor: event.target.value});
    }

    handleChangeRealtorAssociation(event) {
        this.setState({realtorAssociation: event.target.value});
    }

    handleChangeLeadGenerationSystem(event) {
        let leadGenerationSystems = JSON.parse(JSON.stringify(this.state.leadGenerationSystems));
        leadGenerationSystems[event.target.value] = event.target.checked;
        this.setState({"leadGenerationSystems": leadGenerationSystems});
    }

    handleChangeHowDidYouHeardAboutUs(event) {
        this.setState({howDidYouHearAboutUs: event.target.value});
    }
  
    handleSubmit(event) {
        console.log(this.state);
        event.preventDefault();
        this.state.createAccount(this.state, this.onAccountCreationError);
        this.setState({'loading': true});
    }

    onAccountCreationError(errorMessage) {
        console.log(errorMessage);

        if(errorMessage.includes("email-already-in-use")) {
            errorMessage = "Email address already in use";
        }

        this.setState({'errorMessage': errorMessage, 'loading': false});
    }

    addToWaitingList() {
        this.state.addToWaitingList(this.state);
    }

    forwardStep() {
        this.setState({step: this.state.step + 1});
    }

    backwardStep() {
        this.setState({step: Math.max(0, this.state.step - 1)});
    }
  
    render() {

        let stepSection = <></>;
        let shouldShowNext = false;

        switch (this.state.step) {
            case 0:

                let passwordsMatch = this.state.password === this.state.confirmPassword;
                let passwordTooShort = this.state.password.length > 0 && this.state.password.length < 8 && passwordsMatch;
                shouldShowNext = this.state.firstname !== '' && this.state.lastname !== '' && this.state.email !== '' && this.state.phone !== '' && this.state.password !== '' && this.state.confirmPassword !== '' && passwordsMatch && !passwordTooShort;

                stepSection = 
                <>
                    <Form>
                    <Form.Group className="mb-3" controlId="formGroupFirstName">
                        <Form.Label>First name</Form.Label>
                        <Form.Control value={this.state.firstname} onChange={this.handleChangeFirstName} type="text" placeholder="Enter first name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupLastName">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control value={this.state.lastname} onChange={this.handleChangeLastName} type="text" placeholder="Enter last name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control value={this.state.email} onChange={this.handleChangeEmail} type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control value={this.state.phone} onChange={this.handleChangePhone} type="tel" placeholder="Phone Number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control value={this.state.password} onChange={this.handleChangePassword} type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupConfirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control value={this.state.confirmPassword} onChange={this.handleChangeConfirmPassword} type="password" placeholder="Confirm Password" />
                    </Form.Group>

                    {!passwordsMatch && this.state.password.length > 0 && this.state.confirmPassword.length > 0 && <Alert variant="danger">Passwords do not match</Alert>}
                    {passwordTooShort && <Alert variant="danger">Password must be at least 8 characters</Alert>}

                    <Button onClick={this.forwardStep} variant={"primary " + (shouldShowNext ? "" : "disabled")}>
                        Next
                    </Button>
                    </Form>
                </>;
                break;
            case 1:
                shouldShowNext = this.state.address !== '' && this.state.city !== '' && this.state.province !== '' && this.state.country !== '' && this.state.postalCode !== '';

                stepSection = 
                <>
                    <h5>Please confirm your business address</h5>
                    <Form>
                    <Form.Group className="mb-3" controlId="formGroupAddress">
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control value={this.state.address} onChange={this.handleChangeAddress} type="text" placeholder="Address" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control value={this.state.city} onChange={this.handleChangeCity} type="text" placeholder="City" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupCountry">
                        <FloatingLabel controlId="selectCountry" label="Select Country">
                                <Form.Select aria-label="Select country" value={this.state.country} onChange={this.handleChangeCountry}>
                                    <option key={""} value={""}>Country</option>
                                    {Country.getAllCountries().sort(function(a, b) {
                                        if (a.name === "Canada") {
                                            return -1;
                                        }

                                        if (b.name === "Canada") {
                                            return 1;
                                        }

                                        return 0;
                                    }).map((country) => {
                                        return <option key={country.name} value={country.name}>{country.name}</option>;
                                    })}
                                </Form.Select>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupProvince">
                        <FloatingLabel controlId="selectProvince" label="Select State/Province/Territory">
                                <Form.Select aria-label="Select State/Province/Territory" value={this.state.province} onChange={this.handleChangeProvince}>
                                    <option key={""} value={""}>State/Province/Territory</option>
                                    {State.getStatesOfCountry(countryToAlpha2(this.state.country)).map((country) => {
                                        return <option key={country.name} value={country.name}>{country.name}</option>;
                                    })}
                                </Form.Select>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control value={this.state.postalCode} onChange={this.handleChangePostalCode} type="text" placeholder="Postal Code" />
                    </Form.Group>

                    <Stack direction="horizontal" gap={3}>
                        <Button onClick={this.backwardStep} variant="primary">
                            Back
                        </Button>

                        <Button onClick={this.forwardStep} variant={"primary " + (shouldShowNext ? "" : "disabled")}>
                            Next
                        </Button>
                    </Stack>
                    </Form>
                </>;
                break;
            case 2:

                let isCanadianRealtor = this.state.isRealtor === 'yes';
                let isBoardValid = false;
                let selectedBoard = CanadianRealEstateBoards.find((board) => board.name === this.state.realtorAssociation);
                let hasSupportedLeadGenerationSystem = false;

                if(selectedBoard) {
                    isBoardValid = selectedBoard.supported;
                }

                Object.keys(this.state.leadGenerationSystems).forEach((key) => {
                    let system = LeadGenerationSystems.find((system) => system.name === key);

                    if(system.supported && this.state.leadGenerationSystems[key]) {
                        hasSupportedLeadGenerationSystem = true;
                    }
                });

                shouldShowNext = isCanadianRealtor && isBoardValid && hasSupportedLeadGenerationSystem;

                stepSection = 
                <>
                    <h5>Are you a Realtor associated with a Canadian Real Estate Board?</h5>

                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupIsRealtor">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                id="yes-Realtor"
                                name="isRealtor"
                                value="yes"
                                checked={this.state.isRealtor === "yes"}
                                onChange={this.handleChangeIsRealtor}
                            />
                            <Form.Check
                                type="radio"
                                id="no-Realtor"
                                label="No"
                                name="isRealtor"
                                value="no"
                                checked={this.state.isRealtor === "no"}
                                onChange={this.handleChangeIsRealtor}
                            />
                        </Form.Group>

                        {isCanadianRealtor && 
                            <Form.Group className="mb-3" controlId="formGroupRealEstateBoard">
                                <FloatingLabel controlId="selectRealEstateBoard" label="Which Canadian Real Estate Board are you a member of?">
                                    <Form.Select aria-label="Select Real Estate Board" value={this.state.realtorAssociation} onChange={this.handleChangeRealtorAssociation}>
                                        <option key={""} value={""}>Select Real Estate Board</option>
                                        {CanadianRealEstateBoards.map((board) => {
                                            return <option key={board.name} value={board.name}> {board.province} - {board.name}</option>;
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                        }

                        {this.state.isRealtor === 'no' && 
                            <Alert variant="danger">
                                <Alert.Heading>Sorry, unfortunately at this time Lead Buddy is only for Canadian Realtors associated with a Canadian Real Estate Board. </Alert.Heading>
                                <p>
                                    In the future we may expand our services to other countries.
                                </p>
                                <hr />
                                <p className="mb-0">
                                <WaitlistButton addToWaitingList={this.addToWaitingList}/>
                                </p>
                            </Alert>
                        }

                        {isCanadianRealtor && isBoardValid === true && 
                            <>
                                <h5>Which lead generation systems are you subscribed to?</h5>
                                <Form.Group className="mb-3" controlId="formGroupLeadGenSystem">
                                    {LeadGenerationSystems.map((system) => {
                                        return <Form.Check
                                            type="checkbox"
                                            id={`leadgen-${system.name}`}
                                            label={`${system.name}`}
                                            key={`${system.name}`}
                                            name={`${system.name}`}
                                            value={`${system.name}`}
                                            checked={this.state.leadGenerationSystems[system.name] === true}
                                            onChange={this.handleChangeLeadGenerationSystem}
                                        />;
                                    })}
                                </Form.Group>
                            </>
                        }

                        { isCanadianRealtor && isBoardValid === true && 
                            <>
                                {Object.keys(this.state.leadGenerationSystems).map((systemName) => {
                                    let system = LeadGenerationSystems.find((system) => system.name === systemName);
                                    if(this.state.leadGenerationSystems[systemName] === true) {
                                        if(system.supported === false) {
                                            return  <Alert key={system.name} variant="warning">
                                                        <Alert.Heading>{system.name} is currently not supported at this time</Alert.Heading>
                                                        <p>
                                                            We're currently working on intergrating {system.name} into Lead Buddy.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            <WaitlistButton addToWaitingList={this.addToWaitingList}/>
                                                        </p>
                                                    </Alert>;
                                        } else {
                                            return  <Alert key={system.name} variant="success">
                                                        <Alert.Heading>{system.name} is supported</Alert.Heading>
                                                        <p>
                                                            We have setup resources and tutorials to help you get Lead Buddy integraded with {system.name}.
                                                        </p>
                                                    </Alert>;                                    
                                        }
                                    } else {
                                        return null;
                                    }
                                })}
                            </>
                        }

                        {isCanadianRealtor && selectedBoard && isBoardValid === false && 
                            <>
                                <Alert variant="warning">
                                    <Alert.Heading>{this.state.realtorAssociation} is currently not supported</Alert.Heading>
                                    <p>
                                        Aww yeah, you successfully read this important alert message. This
                                        example text is going to run a bit longer so that you can see how
                                        spacing within an alert works with this kind of content.
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        <WaitlistButton addToWaitingList={this.addToWaitingList}/>
                                    </p>
                                </Alert>
                            </>
                        }

                        <Stack direction="horizontal" gap={3}>
                            <Button onClick={this.backwardStep} variant="primary">
                                Back
                            </Button>

                            <Button onClick={this.forwardStep} variant={"primary " + (shouldShowNext ? "" : "disabled")}>
                                Next
                            </Button>
                        </Stack>
                    </Form>
                </>;
                break;
            case 3:
                stepSection = 
                <>
                    <h5>Lastly, how did you hear about Lead Buddy?</h5>
                    <Form>
                    <Form.Group className="mb-3" controlId="howDidYouHearAboutUs">
                        <Form.Label>Optional</Form.Label>
                        <Form.Control value={this.state.howDidYouHearAboutUs} onChange={this.handleChangeHowDidYouHeardAboutUs} as="textarea" rows={3} />
                    </Form.Group>

                    <Stack direction="horizontal" gap={3}>
                        <Button onClick={this.backwardStep} variant="primary">
                            Back
                        </Button>

                        <Button onClick={this.forwardStep} variant="primary">
                                Next
                        </Button>
                    </Stack>
                    </Form>
                </>;
                break;
            case 4:
                stepSection = 
                <>
                    <h5>Confirm your account</h5>
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupFirstName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control disabled  value={this.state.firstname} onChange={this.handleChangeFirstName} type="text" placeholder="Enter first name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupLastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control disabled  value={this.state.lastname} onChange={this.handleChangeLastName} type="text" placeholder="Enter last name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control disabled  value={this.state.email} onChange={this.handleChangeEmail} type="email" placeholder="Enter email" />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formGroupRealEstateBoard">
                                <Form.Label>Real Estate Board</Form.Label>
                                <FloatingLabel controlId="selectRealEstateBoard" label="Which Canadian Real Estate Board are you a member of?">
                                    <Form.Select disabled  aria-label="Select Real Estate Board" value={this.state.realtorAssociation} onChange={this.handleChangeRealtorAssociation}>
                                        <option key={""} value={""}>Select Real Estate Board</option>
                                        {CanadianRealEstateBoards.map((board) => {
                                            return <option key={board.name} value={board.name}> {board.province} - {board.name}</option>;
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                        </Form.Group>

                        {this.state.errorMessage !== undefined && 
                            <Alert variant="danger">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>
                                    {this.state.errorMessage}
                                </p>
                            </Alert>
                        }

                        <Stack direction="horizontal" gap={3}>
                            <Button onClick={this.backwardStep} variant="primary">
                                Back
                            </Button>

                            {this.state.loading && 
                                <Button variant="primary" disabled>
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                    <span style={{marginLeft: '8px'}}>Loading...</span> 
                                </Button>
                            }
                            {!this.state.loading && 
                                <Button onClick={this.handleSubmit} variant="primary">
                                    Create account
                                </Button>
                            }

                        </Stack>
                    </Form>
                </>;
                break;

            default:
                break;
        }

        return (
            <>
                <div className="p-5 bg-light text-dark fade-in fill-out-height" >
                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col md="6">
                                <h1>Create account</h1>
                                <hr />
                                {stepSection}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}