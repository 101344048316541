import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Alert } from "react-bootstrap";

export class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            rememberMe: false,
            onSubmit: props.onSubmit,
            onPasswordReset: props.onPasswordReset,
            errorMessage: '',
            loading: false
        };

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeRememberMe = this.handleChangeRememberMe.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onLoginError = this.onLoginError.bind(this);
        this.onPasswordResetError = this.onPasswordResetError.bind(this);
    }
  
    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }
  
    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }
  
    handleChangeRememberMe(event) {
        this.setState({rememberMe: event.target.checked});
    }
  
    handleSubmit(event) {
        this.setState({loading: true});
        this.state.onSubmit(this.state.email, this.state.password, this.state.rememberMe, this.onLoginError);
        event.preventDefault();
    }

    onLoginError(errorMessage) {
        this.setState({loading: false});

        if(errorMessage === undefined) {
            this.setState({errorMessage: 'Success'});
        } else {
            this.setState({errorMessage: errorMessage});
        }
    }
  
    onPasswordResetError(errorMessage) {
        if(errorMessage !== undefined) {
            this.setState({errorMessage: errorMessage});
        }
    }

    render() {

        let loginButtonContent = 'Login';

        if(this.state.loading === true ) {
            loginButtonContent = <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span style={{marginLeft: '8px'}}>Loading...</span> 
            </>;
        }

        let alert = <></>;

        if(this.state.errorMessage !== undefined && this.state.errorMessage !== '') {
            if(this.state.errorMessage.includes('Error')) {
                if(this.state.errorMessage.includes('wrong-password')) {
                    alert = <Alert variant='danger'>Incorrect password</Alert>;
                } else if (this.state.errorMessage.includes('user-not-found')) {
                    alert = <Alert variant='danger'>No user with this email address found</Alert>;
                } else if(this.state.errorMessage.includes('invalid-email')) {
                    alert = <Alert variant='danger'>Please provide a valid email address</Alert>;
                } else if(this.state.errorMessage.includes('missing-password')) {
                    alert = <Alert variant='danger'>Please provide a password</Alert>;
                } else if(this.state.errorMessage.includes('missing-email')) {
                    alert = <Alert variant='danger'>Please provide a email address</Alert>;
                }else {
                    alert = <Alert variant='danger'>{this.state.errorMessage}</Alert>;
                }
            } else if (this.state.errorMessage === 'Success') {
                alert = <Alert variant='success'>{this.state.errorMessage}</Alert>;
            } else {
                alert = <Alert variant='info'>{this.state.errorMessage}</Alert>;
            }
        }

        return (
        <>
            <div className="p-5 bg-light text-dark fade-in fill-out-height" >
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col md="6">
                            <h1>Login</h1>
                            <hr />
                            {alert}
                            <Form onSubmit={this.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control value={this.state.email} onChange={this.handleChangeEmail} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control value={this.state.password} onChange={this.handleChangePassword} type="password" placeholder="Password" />
                            </Form.Group>
                            <Form.Group className="mb-3" id="formGridCheckbox">
                            <Form.Check checked={this.state.rememberMe} onChange={this.handleChangeRememberMe} type="checkbox" label="Remember me" />
                            </Form.Group>

                            <Button type="submit" variant="primary">
                                {loginButtonContent}
                            </Button>
                            </Form>
                            <br/>
                            <Form onSubmit={(event)=>{
                                this.props.onPasswordReset(this.state.email, this.onPasswordResetError);
                                this.setState({errorMessage: 'Password reset email sent.'});
                                event.preventDefault();
                                }}>
                                <Button style={{padding: '0px'}}type="submit" variant="link">
                                    Send Password Reset Email
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
        );
    }
}