import React from "react";

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

export class MatrixSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: props.matrix_user_id,
            password: '',
            onSubmit: props.onSubmit,
            can_verify: false,
            is_verifying: false,
            verify_result: null
        };

        this.handleChangeUserID = this.handleChangeUserID.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyMatrixCredentials = this.verifyMatrixCredentials.bind(this);
    }
  
    handleChangeUserID(event) {
        this.setState({user_id: event.target.value});
    }
  
    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }
  
    handleSubmit(event) {
        this.state.onSubmit(this.state.user_id, this.state.password);
        this.setState({can_verify: true, verify_result: null});
        event.preventDefault();
    }

    async verifyMatrixCredentials() {
        console.log("Testing matrix credentials");
        this.setState({can_verify: false, is_verifying: true});
        console.log(this.state);
        const response = await fetch("https://us-central1-autoleadenter.cloudfunctions.net/testMatrixCredentials?"+ new URLSearchParams({
            emailAddress: "triciakellerph@gmail.com"
        }), {
            method: "GET",
        });
      
        const result = await response.json();
        console.log("Success:", result);
        this.setState({verify_result: result["result"]});
        this.setState({is_verifying: false});
    }
  
    render() {

        let testMatrixButton = <></>;

        if (this.state.can_verify === true) {
            testMatrixButton = <Button variant="primary" onClick={this.verifyMatrixCredentials}>
                Test Matrix Credentials
            </Button>
        }

        if (this.state.is_verifying === true) {
            testMatrixButton = <Button variant="primary" disabled>
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />
                <span style={{marginLeft: '8px'}}>Loading...</span>            
            </Button>
        }

        let verify_alert = <></>;

        if(this.state.verify_result !== null) {
            if(this.state.verify_result === "success") {
                verify_alert = <Alert variant='success'>
                    Success! Your Matrix credentials are valid.
                </Alert>
            } else {
                verify_alert = <Alert variant='danger'>
                    Error! Your Matrix credentials are invalid.
                </Alert>
            }
        }

        return (
        <>
            <div className="p-5 bg-light text-dark fade-in" >
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col md="6">
                            <Form onSubmit={this.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupUserIDMatrix">
                                <Form.Label>Matrix User ID</Form.Label>
                                <Form.Control value={this.state.user_id} onChange={this.handleChangeUserID} type="text" placeholder="Enter Matrix ID" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPasswordMatrix">
                                <Form.Label>Matrix Password</Form.Label>
                                <Form.Control value={this.state.password} onChange={this.handleChangePassword} type="password" placeholder="Password" />
                                <Form.Label>Note: We'll never share your password with anyone. This field will return blank once our database is updated with your password.</Form.Label>
                            </Form.Group>

                            <Button type="submit" variant="primary">
                                Update Matrix Credentials
                            </Button>
                            </Form>
                            <br/>
                            {testMatrixButton}
                            {verify_alert}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
        );
    }
}