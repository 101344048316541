
const CanadianRealEstateBoards = [
    // Alberta
    {name: "Alberta West REALTORS® Association (ABWRA)", province: "Alberta",  supported: false},
    {name: "Calgary Real Estate Board (CREB)", province: "Alberta",  supported: false},
    {name: "Central Alberta REALTORS® Association (CARA)", province: "Alberta",  supported: false},
    {name: "Fort McMurray REALTORS® (FMR)", province: "Alberta",  supported: false},
    {name: "Grande Prairie & Area Association of REALTORS® (GPAAR)", province: "Alberta",  supported: false},
    {name: "Lethbridge & District Association of REALTORS® (LDAR)", province: "Alberta",  supported: false},
    {name: "Medicine Hat Real Estate Board Co-op (MHREB)", province: "Alberta",  supported: false},
    {name: "REALTORS® Association of Edmonton (RAE)", province: "Alberta",  supported: false},
    {name: "REALTORS® Association of Lloydminster & District (RALD)", province: "Alberta",  supported: false},
    {name: "REALTORS® Association of South Central Alberta (RASCA)", province: "Alberta",  supported: false},

    // British Columbia
    {name: "Association of Interior REALTORS® (AIR)", province: "British Columbia",  supported: false},
    {name: "BC Northern Real Estate Board (BCNREB)", province: "British Columbia",  supported: false},
    {name: "Chilliwack & District Real Estate Board (CADREB)", province: "British Columbia",  supported: false},
    {name: "Fraser Valley Real Estate Board (FVREB)", province: "British Columbia",  supported: false},
    {name: "Kamloops & District Real Estate Association (KDREA)", province: "British Columbia",  supported: false},
    {name: "Kootenay Association of REALTORS® (KAR)", province: "British Columbia",  supported: false},
    {name: "Powell River Sunshine Coast Real Estate Board (PRSCREB)", province: "British Columbia",  supported: false},
    {name: "Real Estate Board Of Greater Vancouver (REBGV)", province: "British Columbia",  supported: false},
    {name: "Vancouver Island Real Estate Board (VIREB)", province: "British Columbia",  supported: true},
    {name: "Victoria Real Estate Board (VREB)", province: "British Columbia",  supported: true},

    // Manitoba
    {name: "Brandon Area REALTORS® (BAR)", province: "Manitoba",  supported: false},
    {name: "Winnipeg Regional Real Estate Board (WRREB)", province: "Manitoba", supported: false},

    // New Brunswick
    {name: "Greater Moncton REALTORS® du Grand Moncton (GMRGM)", province: "New Brunswick",  supported: false},
    {name: "New Brunswick Real Estate Association (NBREA)", province: "New Brunswick", supported: false},
    {name: "Saint John Real Estate Board Inc (SJREB)", province: "New Brunswick",  supported: false},
    {name: "The Real Estate Board of Fredericton Area Inc. (REBFA)", province: "New Brunswick", supported: false},

    // Newfoundland and Labrador
    {name: "The Newfoundland & Labrador Association of REALTORS® (NLAR)", province: "Newfoundland and Labrador", supported: false},

    // Nova Scotia
    {name: "Nova Scotia Association of REALTORS® (NSAR)", province: "Nova Scotia", supported: false},

    // Ontario
    {name: "Barrie & District Association of REALTORS® Inc. (BDAR)", province: "Ontario",  supported: false},
    {name: "Brampton Real Estate Board (BREB)", province: "Ontario", supported: false},
    {name: "Brantford Regional Real Estate Assn Inc (BRREA)", province: "Ontario",  supported: false},
    {name: "Cambridge Association of REALTORS® Inc. (CAR)", province: "Ontario", supported: false},
    {name: "Chatham Kent Association of REALTORS® (CKREB)", province: "Ontario", supported: false},
    {name: "Cornwall & District Real Estate Board (CDREB)", province: "Ontario", supported: false},
    {name: "Durham Region Association of REALTORS® (DRAR)", province: "Ontario", supported: false},
    {name: "Guelph & District Association of REALTORS® (GDAR)", province: "Ontario", supported: false},
    {name: "Huron Perth Association of REALTORS® (HPAR)", province: "Ontario", supported: false},
    {name: "Kawartha Lakes Real Estate Association (KLREA)", province: "Ontario", supported: false},
    {name: "Kingston & Area Real Estate Association (KAREA)", province: "Ontario", supported: false},
    {name: "Kitchener-Waterloo Association of REALTORS® (KWAR)", province: "Ontario", supported: false},
    {name: "London and St. Thomas Association of REALTORS® (LSTAR)", province: "Ontario", supported: false},
    {name: "Mississauga Real Estate Board (MREB)", province: "Ontario", supported: false},
    {name: "Niagara Association of REALTORS® (NAR)", province: "Ontario", supported: false},
    {name: "North Bay and Area REALTORS® Association (NBARA)", province: "Ontario", supported: false},
    {name: "Northumberland Hills Association of REALTORS® (NHAR)", province: "Ontario", supported: false},
    {name: "Ottawa Real Estate Board (OREB)", province: "Ontario", supported: false},
    {name: "Peterborough & Kawarthas Association REALTORS® (PKAR)", province: "Ontario", supported: false},
    {name: "Quinte & District Association of REALTORS® Inc. (QDAR)", province: "Ontario", supported: false},
    {name: "REALTORS® Association of Grey Bruce Owen Sound (RAGBOS)", province: "Ontario", supported: false},
    {name: "REALTORS® Association of Hamilton-Burlington (RAHB)", province: "Ontario", supported: false},
    {name: "Renfrew County Real Estate Board (RCREB)", province: "Ontario", supported: false},
    {name: "Rideau - St. Lawrence Real Estate Board (RSLREB)", province: "Ontario", supported: false},
    {name: "Sarnia-Lambton Association of REALTORS® (SLAR)", province: "Ontario", supported: false},
    {name: "Sault Ste. Marie Real Estate Board (SSMREB)", province: "Ontario", supported: false},
    {name: "Simcoe & District Real Estate Board (SDREB)", province: "Ontario", supported: false},
    {name: "Sudbury Real Estate Board (SREB)", province: "Ontario", supported: false},
    {name: "The Lakelands Association of REALTORS® (LAR)", province: "Ontario", supported: false},
    {name: "The Oakville, Milton & District Real Estate Board (OMDREB)", province: "Ontario", supported: false},
    {name: "Thunder Bay Real Estate Board (TBREB)", province: "Ontario", supported: false},
    {name: "Timmins, Cochrane & Timiskaming District Association of REALTORS® (TCTDAR)", province: "Ontario", supported: false},
    {name: "Toronto Regional Real Estate Board (TRREB)", province: "Ontario", supported: false},
    {name: "Waterloo Region Association of REALTORS® (WRAR)", province: "Ontario", supported: false},
    {name: "Windsor-Essex County Association of REALTORS® (WECREB)", province: "Ontario", supported: false},
    {name: "Woodstock Ingersoll Tillsonburg & Area Association of REALTORS® (WITAAR)", province: "Ontario", supported: false},

    // Prince Edward Island
    {name: "Prince Edward Island Real Estate Association (PEIREA)", province: "Prince Edward Island", supported: false},

    // Quebec
    {name: "Greater Montréal Real Estate Board (GMREB)", province: "Quebec", supported: false},

    // Saskatchewan
    {name: "Saskatchewan REALTORS® Association (SRA)", province: "Saskatchewan", supported: false},

    // Yukon
    {name: "Yukon Real Estate Association (YREA)", province: "Yukon", supported: false},

    {name: "Other", supported: false},
];

const LeadGenerationSystems = [
    {name: "Real Geeks", supported: false},
    {name: "CINC Pro", supported: false},
    {name: "Real Estate Leads", supported: true},
    {name: "Bold Leads", supported: false},
    {name: "Lead Hornet", supported: false},
    {name: "Other", supported: false},
];

export { CanadianRealEstateBoards, LeadGenerationSystems };