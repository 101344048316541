import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export class LeadBuddySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: props.firstname,
            lastname: props.lastname,
            email: props.email,
            password: '',
            password_confirm: '',
            onSubmit: props.onSubmit,
        };

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChangeFirstName(event) {
        this.setState({firstname: event.target.value});
    }

    handleChangeLastName(event) {
        this.setState({lastname: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }
  
    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    handleChangePasswordConfirm(event) {
        this.setState({password_confirm: event.target.value});
    }
  
    handleSubmit(event) {

        let password = this.state.password;
        if (this.state.password !== this.state.password_confirm) {
            password = undefined;
        }

        this.state.onSubmit(this.state.firstname, this.state.lastname, this.state.email, password);
        event.preventDefault();
    }
  
    render() {
      return (
        <>
            <div className="p-5 bg-light text-dark fade-in" >
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col md="6">
                            <Form onSubmit={this.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupLeadBuddyFirstName">
                                <Form.Label>First name</Form.Label>
                                <Form.Control value={this.state.firstname} onChange={this.handleChangeFirstName} type="text" placeholder="Enter your first name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupLeadBuddyLastName">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control value={this.state.lastname} onChange={this.handleChangeLastName} type="text" placeholder="Enter your last name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupLeadBuddyEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control value={this.state.email} onChange={this.handleChangeEmail} type="email" placeholder="Enter email address" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPasswordLeadBuddy">
                                <Form.Label>Password</Form.Label>
                                <Form.Control value={this.state.password} onChange={this.handleChangePassword} type="password" placeholder="Password" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPasswordLeadBuddyConfirm">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control value={this.state.password_confirm} onChange={this.handleChangePasswordConfirm} type="password" placeholder="Password" />
                            </Form.Group>

                            <Button type="submit" variant="primary">
                                Update Lead Buddy Credentials
                            </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
        );
    }
}