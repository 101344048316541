import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { LeadSection } from "./LeadSection";
import { MatrixSection } from "./MatrixSection";
import { LeadBuddySection } from "./LeadBuddySection";

function Greeting(name) {
    let today = new Date();
    let dayOfWeek = today.getDay();

    return {
        0: `Welcome ${name}, Happy Sunday`,
        1: `Hi ${name}, let's have a great week`,
        2: `Happy Tuesday ${name}`,
        3: `Greetings ${name}, have a productive Wednesday`,
        4: `Hello ${name}, have a great Thursday`,
        5: `It's Friday ${name}, have a great weekend`,
        6: `Happy Saturday ${name}, enjoy your weekend`,
    }[dayOfWeek];
}

function AlertSection(props) {
    return(
        <Row className="d-flex justify-content-center">
            <Col sm="12" md="12" lg="12" xl="7" xxl="6">
                {props.accountErrors.map((error) => {
                    return <Alert key={error.message} variant={error.severity} onClose={()=>{props.removeAlert(error);}} dismissible>
                        {error.message}
                    </Alert>
                })} 
            </Col>  
        </Row>
    )
}

export function ProfilePage(props) {

    /*
    <Tab eventKey="leadgen-setup" title="Lead Generation Settings">
        Tab content for Lead Generation Settings
    </Tab>
    <Tab eventKey="payment" title="Payment">
        Tab content for Payment
    </Tab>
    */

    return (
        <>
            <div className="p-5 bg-light text-dark fade-in fill-out-height">
                <Row className="d-flex justify-content-center fade-in-long">
                    <Col sm="12" md="12" lg="12" xl="7" xxl="6">
                            <h1>{Greeting(props.firstname)} </h1>
                            <hr />
                    </Col>
                </Row>
                <AlertSection accountErrors={props.accountErrors} removeAlert={props.removeAlert}/>
                <Row className="d-flex justify-content-center">
                    <Col sm="12" md="12" lg="12" xl="7" xxl="6">
                        <Tabs
                            defaultActiveKey="leads"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                            >
                            <Tab eventKey="leads" title="Leads">
                                <LeadSection leads={props.leads} viewSetup={props.viewSetup}/>
                            </Tab>
                            <Tab eventKey="matrix-setup" title="MLS Settings">
                                <MatrixSection matrix_user_id={props.matrix_user_id} onSubmit={props.matrix_update_callback}/>
                            </Tab>
                            <Tab eventKey="profile" title="Lead Buddy Profile">
                                <LeadBuddySection firstname={props.firstname} lastname={props.lastname} email={props.email} onSubmit={props.lead_buddy_update_callback}/>
                            </Tab>
                        </Tabs>               
                    </Col>
                </Row>
            </div>
        </>
    );
}