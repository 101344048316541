import React from "react";

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { LeadSection } from "./LeadSection";
import { MatrixSection } from "./MatrixSection";
import { LeadBuddySection } from "./LeadBuddySection";
import { Stack } from "react-bootstrap";
import Image from 'react-bootstrap/Image';

import realEstateLeadsSettingsImg from './imgs/setup3.png';
import matrixLeadBuddyImg from './imgs/setup2.png';
import realEstateLeadsEmailImg from './imgs/setup1.png';
import realEstateLeadsTeamEmailImg from './imgs/setup6.png';
import teamImg from './imgs/setup4.png';

export function InstructionsPage(props) {

    return (
        <>
            <div className="p-5 bg-light text-dark fade-in fill-out-height">
                <Row className="d-flex justify-content-center fade-in-long">
                    <Col sm="12" md="12" lg="12" xl="7" xxl="6">
                            <h1>Setup Instructions</h1>
                            <p>
                                These setup instructions assume that you have already created a Lead Buddy account.
                            </p>
                            <hr />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">

                    <Col sm="12" md="12" lg="12" xl="7" xxl="6">   
                        <Accordion defaultActiveKey={['0', '1', '3']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h3>1) Setting up Lead Buddy with VREB/VIREB Matrix</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>To integrate Lead Buddy with VREB/VIREB Matrix, follow these steps:</p>     

                                    <Stack gap={1}>
                                        <p><b>a)</b> Log in to your Lead Buddy account at <a href="https://leadbuddy.ca/" target="_blank">leadbuddy.ca</a> if you are not already there.</p>
                                        <p><b>b)</b> Navigate to your account page within the Lead Buddy application.</p>
                                        <p><b>c)</b> Click on the "MLS Settings" tab, located next to the "Leads" tab.</p>
                                        <p><b>d)</b> On the MLS Settings page, enter your Matrix User ID and Password.</p>
                                        <p><b>e)</b> After updating your Matrix credentials, it is strongly recommended to test their validity. This quick test ensures that your leads can be seamlessly entered into your Matrix account.</p>
                                        <Image style={{ boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',  borderRadius: "8px", maxWidth:"500px", width: '100%', margin:'auto'}} src={matrixLeadBuddyImg} />
                                        <br/>
                                    </Stack>    
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h3>2) Integrating Real Estate Leads</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>To configure Real Estate Leads, please follow these steps:</p>     

                                    <Stack gap={1}>
                                        <p><b>a)</b> Visit realestateleads.ca and log in to your account.</p>
                                        <p><b>b)</b> Access your account's <b>General Settings</b> page.</p>
                                        <Image style={{ boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',  borderRadius: "8px", maxWidth:"800px", width: '100%', margin:'auto'}} src={realEstateLeadsSettingsImg} />
                                        <br/>
                                        <p><b>c)</b> In the <b>General Settings</b> page, find the <b>Notification Email Addresses</b> section.</p>

                                        <p>If you have a Realtor Leads <b>Team Account</b> please scoll down on the <b>General Settings</b> page and select <b>Add Signature</b>.</p>
                                        <Image style={{ boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',  borderRadius: "8px", maxWidth:"600px", width: '100%', margin:'auto'}} src={teamImg} />
                                        <br/>

                                        <p>
                                            <b>d)</b> Your dedicated Lead Buddy email address is: <b>{props.userID}@leadbuddy.ca</b>
                                            <br/>
                                            <br/>
                                            For <b>Single User Realtor Lead accounts</b>, on the General Settings page ensure that your dedicated Lead Buddy email address as a <b>Notification Email Address</b>.
                                            <br/>    
                                            <br/>    
                                        </p>
                                        <Image style={{ boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',  borderRadius: "8px", maxWidth:"600px", width: '100%', margin:'auto'}} src={realEstateLeadsEmailImg} />
                                        <br/>    
                                        <p>
                                            <br/>                                                                                    
                                            For <b>Team Realtor Lead accounts</b> find your Email signature box on the Team Signatures page and add your dedicated Lead Buddy email address as a <b>CC Email</b>. 
                                            <br/>                                            
                                            <br/>
                                        </p>
                                        <Image style={{ boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',  borderRadius: "8px", maxWidth:"600px", width: '100%', margin:'auto'}} src={realEstateLeadsTeamEmailImg} />
                                        <br/>   
                                        <br/>                                                                                     
                                        <p><b>e)</b> Once your Lead Buddy email address has been added, click the green "Save" button. To confirm your changes have been saved, refresh the page and verify your changes persist.</p>
                                    </Stack> 
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h3>🎉 Congratulations!</h3></Accordion.Header>
                                <Accordion.Body>
                                    <Stack gap={1}>
                                        <p>Once you have completed the above steps, Lead Buddy will start processing all your future leads seamlessly.</p>
                                        <p>Contact us at <a href="mailTo:contact@leadbuddy.ca">contact@leadbuddy.ca</a> if further assistance is required.</p>
                                    </Stack> 
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </div>
        </>
    );
}